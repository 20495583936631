import styled from 'styled-components'

const PHONE_NUMBER = '+48 576 183 518'

const Phone = styled.a`
    color: var(--black);
    text-decoration: underline;
    font-size: var(--default-font-size);
`

interface IProps {
    phone?: string
}

export default ({ phone }: IProps) => {
    return (
        <Phone
            href={`tel:${phone ?? PHONE_NUMBER}`}
        >
            {
                phone ?? PHONE_NUMBER
            }
        </Phone>
    )
}